import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import { usePreferences } from "../../utils/PreferencesContext";

const ASCIIDonut = () => {
    const preRef = useRef<HTMLPreElement>(null);
    const { reducedMotion } = usePreferences();
    const [animationRunning, setAnimationRunning] = useState(!reducedMotion);

    // Store animation state (z and y)
    const [z, setZ] = useState(0);
    const [y, setY] = useState(0);

    const renderFrame = (zVal: number, yVal: number) => {
        let x = 1760;

        const a: string[] = [...new Array(x)].map((_, r) => r % 80 === 79 ? "<br />" : " ");
        const r = new Array(x).fill(0);
        const t = Math.cos(zVal), e = Math.sin(zVal), n = Math.cos(yVal), o = Math.sin(yVal);
        for (let s = 0; s < 6.28; s += 0.07) {
            const c = Math.cos(s), h = Math.sin(s);
            for (let s = 0; s < 6.28; s += 0.02) {
                const v = Math.sin(s), M = Math.cos(s), i = c + 2, l = 1 / (v * i * e + h * t + 5),
                    p = v * i * t - h * e, d = 0 | 40 + 30 * l * (M * i * n - p * o),
                    m = 0 | 12 + 15 * l * (M * i * o + p * n),
                    f = 0 | 8 * ((h * e - v * c * t) * n - v * c * e - h * t - M * c * o),
                    y = d + 80 * m;
                if (m < 22 && m >= 0 && d >= 0 && d < 79 && l > r[y]) {
                    r[y] = l;
                    a[y] = ".,-~:;=!*#$@"[f > 0 ? f : 0];
                }
            }
        }
        preRef.current!.innerHTML = a.join("");
    };

    useEffect(() => {
        let interval: NodeJS.Timer | null = null;

        // Render the first frame regardless of the reducedMotion setting
        renderFrame(z, y);

        if (animationRunning) {
            interval = setInterval(() => {
                setZ((prevZ) => prevZ + 0.07);
                setY((prevY) => prevY + 0.03);
                renderFrame(z + 0.07, y + 0.03);  // Render the updated frame
            }, 50);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [animationRunning, z, y]);

    // Update animation state based on reducedMotion
    useEffect(() => {
        setAnimationRunning(!reducedMotion);
    }, [reducedMotion]);

    return <pre ref={preRef} className={`donut ${reducedMotion ? 'reduced' : ''}`}></pre>;
};

export default ASCIIDonut;
