const translations: any = {
    en: {
        header: {
            homeLink: "Home",
            contactLink: "Contact",
            aboutLink: "About Me",
            staticButton: "Static",
            animatedButton: "Animate",
        },
        home: {
            area: 'Home area',
            webDev: 'Web Developer -',
            frontend: 'Frontend',
            crafting: 'Crafting',
            websites: 'Websites',
            ofAny: 'of Any',
            complexityWith: 'Complexity with',
            artistic: 'Artistic',
            flair: 'Flair',
            version: 'WEB version',
            tanzanite: 'Tanzanite'
        },
        experience: {
            myExperience: 'My Experience:',
            projects: 'Projects',
            technologies: 'Technologies',
            projectsList: {
                name: 'Name',
                shortDesc: 'Short Description',
                technologies: 'Technologies',
                images: 'Images',
                url: 'URL',
                source: 'Source',
                deployed: 'Deployed?',
                releaseDate: 'Release Date',
                difficulty: 'Difficulty'
            },
            technologiesList: {
                name: 'Name',
                language: 'Language',
                description: 'Description',
                startDate: 'Start Date',
                knowledgeLevel: 'Knowledge'
            }
        },
        project: {
            liveLink: 'View Live',
            moreProjects: 'More Projects'
        },
        contact: {
            emailMe: 'Email Me',
            myResume: 'My Resume',
            scheduleMeeting: 'Schedule a Meeting',
            projectsCompleted: 'Projects Completed',
            learnedTechnologies: 'Learned Technologies',
            developingExperience: 'Developing Experience',
            suffix: '+ years'
        },
        footer: {
            version: 'Version',
            productive: 'Have a productive day',
            evening: 'Good evening',
            night: 'Good night',
            morning: 'Good morning'
        }
    },
    ru: {
        header: {
            homeLink: "Главная",
            contactLink: "Контакты",
            aboutLink: "Обо мне",
            staticButton: "Скорость",
            animatedButton: "Анимации",
        },
        home: {
            area: 'Главная страница',
            webDev: 'Веб Разработчик -',
            frontend: 'Фронтенд',
            crafting: 'Создаю',
            websites: 'Вебсайты',
            ofAny: 'Любой',
            complexityWith: 'Сложности с',
            artistic: 'Творческим',
            flair: 'Подходом',
            version: 'WEB вирсия:',
            tanzanite: 'Tanzanite'
        },
        experience: {
            myExperience: 'Мой опыт:',
            projects: 'Проекты',
            technologies: 'Технологии',
            projectsList: {
                name: 'Название',
                shortDesc: 'Краткое Описание',
                technologies: 'Технологии',
                images: 'Медиа',
                url: 'Ссылка',
                source: 'Исходник',
                deployed: 'Используется?',
                releaseDate: 'Дата выхода',
                difficulty: 'Сложность'
            },
            technologiesList: {
                name: 'Название',
                language: 'Язык',
                description: 'Описание',
                startDate: 'Дата Начала',
                knowledgeLevel: 'Знание'
            }
        },
        project: {
            liveLink: 'Перейти на сайт',
            moreProjects: 'Остальные проекты'
        },
        contact: {
            emailMe: 'Моя Почта',
            myResume: 'Моё Резюме',
            scheduleMeeting: 'Назначить Встречу',
            projectsCompleted: 'Выполнено Проектов',
            learnedTechnologies: 'Технологий Изучено',
            developingExperience: 'Опыт Разработки',
            suffix: '+ лет'
        },
        footer: {
            version: 'Версия',
            productive: 'Продуктивного дня',
            evening: 'Добрый вечер',
            night: 'Спойоной ночи',
            morning: 'Доброе утро'
        }
    },
};

export default translations;
